<template>
  <div class="card">
    <div class="card-body pt-0 mt-5 steps">
      <el-steps :active="active" finish-status="success">
        <el-step :title="weightImportConstants.WEIGHTIMPORT" />
        <el-step :title="weightImportConstants.WEIGHT_STATISTICS" />
        <el-step :title="generalConstants.FINISH" class="text-end" />
      </el-steps>
      <el-form
        :model="productDownloadFormData"
        :rules="rules"
        ref="productDownloadFormRef"
        class="w-100 mt-10"
      >  
        <div class="row" v-if="active == 0"> 
          <div class="col-md-3">
            <label class="fs-6 fw-bold mb-2"> {{ generalConstants.SELECTPRODUCT }}</label>
            <el-form-item prop="productId">
              <el-select
                v-model="productDownloadFormData.productId"
                filterable
                :placeholder="generalConstants.SELECTPRODUCT"
                size="large"
                @change="setProductName($event)"
              >
              <el-option :label="prodcutsData.product_name" :value="prodcutsData.product_id" :key="prodcutsData"  v-for="prodcutsData in productData"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-2">
            <el-button size="large" class="mt-8" type="primary" :loading="loading" @click="downloadWeightData('xlsx')">{{ weightImportConstants.DOWNLOADWEIGHTDATAEXCEL }}</el-button>
          </div>
          <div class="col-md-2">
            <el-button size="large" class="mt-8" type="primary" :loading="loading" @click="downloadWeightData('csv')">{{ weightImportConstants.DOWNLOADWEIGHTDATACSV }}</el-button>
          </div>
        </div>
      </el-form>
      <el-form
        @submit.prevent="submitProductWeightForm()"
        :model="productImportFormData"
        :rules="rules"
        ref="productFormRef"
        class="w-100 mt-5"
      >  
      <div class="row" v-if="active == 0">
        <div class="col-md-3">
          <label class="fs-6 fw-bold mb-2">{{ generalConstants.SELECTPRODUCTEXCEL }}</label>
          <el-form-item prop="excelFiles">
          <el-upload
            v-model="productImportFormData.excelFiles"
            :auto-upload="false"
            :limit="2"
            :multiple="false"
            :on-remove="handleRemove"
            v-on:change="handleChange"
            :file-list="fileList"
            drag
            class="upload-exel"
            action=""
            >
            <div class="fv-row">
              <div class="dropzone" id="kt_dropzonejs_example_1">
                  <div class="dz-message needsclick">
                      <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                      <div class="ms-4">
                          <h3 class="fs-5 fw-bolder text-gray-900 mb-1 mt-4" v-html="generalConstants.UPLOADFILEPLACETEXT"></h3>
                      </div>
                  </div>
              </div>
            </div>
          </el-upload>
          </el-form-item>
        </div>
      </div>

      <span class="row mt-10" v-if="active == 1" v-loading="loading">  
        <div class="col-md-3 mb-1">
          <div class="col bg-light-primary px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-primary fw-bold fs-6"> {{ 'Total Rows' }} <h1 class="text-primary"><strong>{{ totalWeightCount }}</strong></h1></span>
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div class="col bg-light-danger px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-danger fw-bold fs-6"> {{ 'Duplicate SKU' }} <h1 class="text-danger"><strong>{{ totalDuplicateSkuFound }}</strong></h1></span>
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div class="col bg-light-success px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-success fw-bold fs-6"> {{ 'Total Weight Import' }} <h1 class="text-success"><strong>{{ totalImportWeightCount }}</strong></h1></span>
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div class="col bg-light-danger px-6 py-8 rounded-2 me-7  mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
            </span>
            <span class="text-danger fw-bold fs-6"> {{ 'Skipped Rows' }} <h1 class="text-danger"><strong>{{ totalWeightNotFoundCount }}</strong></h1></span>
          </div>
        </div>
      </span>

      <span v-if="active > 1" v-loading="loading">  
        <el-row>
          <el-col :md="8"></el-col>
          <el-col :md="7" class="text-center">
          <div class="alert alert-success d-flex flex-column flex-sm-row p-5 mb-10">
              <span class="svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                </svg>
              </span>
              <div class="d-flex flex-column pe-0 pe-sm-10">
                  <h4 class="mb-2 text-center">{{ generalConstants.SUCCESS }}</h4>
                  <span class="text-center">{{ weightImportConstants.WEIGHTIMPORTMESSAGE }}</span>
              </div>
          </div>
            <el-button size="large" type="primary" @click.prevent="$router.push({ name: 'product-weight'})">{{ weightImportConstants.WEIGHTIMPORTREDIRECTBTN }}</el-button>
        </el-col>
        </el-row>
      </span>
      <div class="col-md-12 mt-10 d-flex justify-content-between">
        <div>
          <button type="button" size="large" v-bind:class="btnHide" :disabled="disabled" v-if="active > 0" class=" btn btn-primary me-2" :loading="loading" @click="back">{{generalConstants.PREVIOUS}}</button>
        </div>
        <div>
          <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" @click="submitProductWeightForm(productFormRef)">{{generalConstants.NEXT}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
          <button type="button" size="large" class=" btn btn-secondary me-2" v-bind:class="btnHide" @click.prevent="$router.push({ name: 'product-weight'})">{{generalConstants.CANCEL}}</button>
        </div>
      </div>
    </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import useEventBus from "../../composable/useEventBus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import axios from "axios";

export default {
  name: "weight-import",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const btnHide = ref();
    const formRef = ref(null);
    const loading = ref(false);
    const file = ref();
    const active = ref(0);
    const disabled = ref(true);
    const excelData = ref([]);
    const productData = reactive([]);
    const productFormRef = ref(null);
    const productDownloadFormRef = ref(null);
    const weightCount = ref(0);
    const totalWeightCount = ref(0);
    const totalImportWeightCount = ref(0);
    const totalWeightNotFoundCount = ref(0);
    const totalDuplicateSkuFound = ref(0);
    const generalConstants = globalConstant.general;
    const weightImportConstants = globalConstant.weight;
    const fileList = ref([]);
    const productDownloadFormData = ref({
      productId: "",
      productName: ""
    });

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/product-weight');
      }
    })

    const productImportFormData = ref({
      excelFiles: "",
    });

    const rules = ref({
      productId: [ { required: true, message: generalConstants.PRODUCTREQUIRED, trigger: "change" } ],
      excelFiles: [ { required: true, message: generalConstants.EXCELFILEREQUIRED, trigger: "change" } ],
    });

    const handleRemove = (e) => {
      productImportFormData.value.excelFiles = '';
    }

    const handleChange = (e) => {
      if(fileList.value.length > 1){
        fileList.value.splice(0, 1);
      }
      file.value = e.raw;
      productImportFormData.value.excelFiles = e.raw;
    }
    
    const back = () => {
      if (active.value-- < 2) active.value = 0
    }

    const setProductName = (val) => {
      let index = productData.findIndex(function(product) {
        return product.product_id == val
      });
      productDownloadFormData.value.productName = productData[index].product_name;
    }

    const downloadWeightData = (extension) => {
      if (!productDownloadFormRef.value) {
        return;
      }
      if(extension != "" && (extension == 'csv' || extension == 'xlsx')){
        productDownloadFormRef.value.validate((valid) => {
          if (!valid) return false;
          axios({
            url: 'product-weight/export-product-weight-data',
            method: 'POST',
            data: {
              productId: productDownloadFormData.value.productId,extension: extension
            },
            responseType: (extension == 'csv') ? '' : 'arraybuffer',
        }).then((response) => {            
            var blob = new Blob([response.data], {
                type: (extension == 'csv')?'text/csv;charset=UTF-8':''
            });
            let link = document.createElement('a')
            link.setAttribute('target','_blank')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'weight_'+productDownloadFormData.value.productName.replace(/ /g,"_")+'_'+Date.now()+'.'+extension
            link.click()
        })
        .catch((error) => {
          let response = error;
            if (response != "") {
              notificationFire(weightImportConstants.WEIGHTEXCELEXPORTED, "error");
            }
        });
        return false;
        });
      }else{
        notificationFire('Something went wrong.', "error");
      }
    }

    //Fetch the all the products
    const getProducts = () => {
      ApiService.query('get-active-products')
        .then(({ data }) => {
          if (data) {
            data.data.forEach((obj) => {
              productData.push({
                product_id: obj.product_id,
                product_name: obj.name,
              });
            });
          }
        })
        .catch((error) => {
          let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404 || status == 422) {
                router.push({ name: 'product-weight'})
              }
            }
        });
    }

  const submitProductWeightForm = async (formEl) => {
    if (!productFormRef.value) {
      return;
    }
    productFormRef.value.validate((valid) => {
      if (!valid) return false;
      if(productDownloadFormData.value.productId == ''){
        notificationFire(weightImportConstants.PRODUCTOPTIONVALIDATION, "error");
        return false;
      }
      const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
      if(active.value == 0){
        loading.value = true;
        disabled.value = true;
        let formData = new FormData();
        formData.append('file', file.value);
        formData.append('steps', active.value);
        formData.append('productId', productDownloadFormData.value.productId);
        axios.post('product-weight/import-product-weight-data', formData, config)
        .then(({ data }) => {
            if(data.data){
              totalWeightCount.value = data.data.totalWeightCount;
              totalImportWeightCount.value = data.data.totalImportWeightCount;
              totalWeightNotFoundCount.value = data.data.totalWeightNotFoundCount;
              totalDuplicateSkuFound.value = data.data.totalDuplicateSkuFound;
              active.value = 1;
            }
            sessionStorage.setItem("weightFileName", data.data.weightFileName);
            sessionStorage.setItem("weightFileExt", data.data.weightFileExt);
            disabled.value = false;
            loading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          loading.value = false;
        });
      }
      if(active.value == 1){
        loading.value = true;
        disabled.value = true;
        let formData = new FormData();
        formData.append('file', file.value);
        formData.append('steps', active.value);
        formData.append('productId', productDownloadFormData.value.productId);
        if(sessionStorage.getItem("weightFileName")){
          formData.append('weightFileName', sessionStorage.getItem("weightFileName"));
        }
        if(sessionStorage.getItem("weightFileExt")){
          formData.append('weightFileExt', sessionStorage.getItem("weightFileExt"));
        }
        axios.post('product-weight/import-product-weight-data', formData, config)
        .then(({ data }) => {
            notificationFire(data.message, "success");
            disabled.value = false;
            loading.value = false;
            weightCount.value = data.data;
            active.value = 3;
            btnHide.value = 'd-none';
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          loading.value = false;
        });
      }
    });
  };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and get the products
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": formatText('product-weight'), "path": "product-weight"}, { name: formatText(route.name), active: true }]);
      getProducts();
      generateHeaderToolbarButtons();
    })

    return {
      file,
      btnHide,
      loading,
      rules,
      active,
      disabled,
      back,
      excelData,
      submitProductWeightForm,
      productFormRef,
      productImportFormData,
      handleChange,
      handleRemove,
      productData,
      downloadWeightData,
      productDownloadFormData,
      productDownloadFormRef,
      weightCount,
      generalConstants,
      weightImportConstants,
      fileList,
      totalWeightCount,
      totalImportWeightCount,
      totalWeightNotFoundCount,
      totalDuplicateSkuFound,
      setProductName
    };
  },
};
</script>
<style>
  .upload-exel .el-upload-dragger{border:none !important;height:auto;padding:0 !important;}
  .steps .el-step__icon{
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .steps .el-step__head.is-process{
    color: #009ef7;
    font-weight:700;
  }
  .steps .el-step.is-horizontal .el-step__line{
    top:50%;
    display: block;
  }
  .steps .el-step__head.is-process {  
  border-color: #009ef7;
  }
  .steps .el-step__main{
    color: #009ef7;
  }
  .steps .el-step__title.is-process{
    color: #009ef7 !important;
  }
  .steps  .el-step__title.is-success {
    color:#009ef7;
    font-weight: 700;
  }
  .steps .el-step__head.is-success {
    color:#fff;
  }
  .steps  .el-step__head.is-success .el-step__line{
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success .el-step__icon{
    border-color: #009ef7;
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success{
    border-color: #009ef7;
  }
  .steps .form-check-input {
    width: 1.30em;
    height: 1.30rem;
    margin-right: 10px;
  }
</style>